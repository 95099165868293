body {
  margin: 0;
  font-family:
    'Montserrat',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  font-size: 14px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

:root {
  --rs-bg-active: #f7875d;
  --rs-calendar-cell-selected-hover-bg: #f7875d;
  --rs-btn-primary-active-bg: #d87550;
  --rs-btn-link-hover-text: #f7875d;
  --rs-input-focus-border: #f7875d;
  --rs-calendar-range-bg: #f7865d24;
  --rs-listbox-option-hover-bg: #f7865d24;
  --rs-listbox-option-hover-text: #000;

  --rs-btn-link-text: #29343d;
  --rs-btn-primary-bg: #f7875d;
  --rs-btn-primary-hover-bg: #f7865dca;
}

.rs-picker-daterange-header {
  text-align: center;
}

.rs-picker-daterange-predefined {
  justify-content: space-between;
  min-width: 140px;
  padding: 6px;
}

.rs-btn-sm {
  font-weight: 500;
  width: 100%;
  text-align: left;
}

.rs-picker-daterange-predefined .rs-stack-item {
  width: 100%;
  text-align: left;
}

.rs-btn-link:hover,
.rs-btn-link:focus {
  text-decoration: none;
  background-color: var(--rs-listbox-option-hover-bg);
  color: inherit;
}

@media (max-width: 450px) {
  .rs-picker-daterange-panel > .rs-stack {
    flex-direction: column;
  }

  .rs-picker-daterange-panel > .rs-stack > .rs-stack-item {
    width: 100%;
    border-right: 0;
  }

  .rs-picker-daterange-predefined {
    width: 100%;
    display: none;
  }
}

header {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px -1px !important;
}

.MuiOutlinedInput-root .MuiAutocomplete-input {
  padding: 1px 4px !important;
}

.MuiAutocomplete-listbox {
  max-height: 160px !important;
  padding: 0 !important;
}
